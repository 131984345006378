<template>
	<div v-if="question" class="trivia-question">
		<div class="bg"></div>

		<div class="top">
			<div class="top-title">Question {{ no }} / {{ total }}</div>
		</div>

		<div class="content">
			<ui-text-fit
				v-if="current_question_type === 'text'"
				class="text question"
				element-class="question"
			>
				<div v-html="current_question_text" />
			</ui-text-fit>
			<carousel
				v-if="current_question_type === 'carousel'"
				:slides="carousel_slides"
				:key="question.id"
				@loaded="set_loaded(false)"
			/>
		</div>
	</div>
</template>

<script>
import Carousel from '@/components/game/trivia/carousel'
import uiTextFit from '@/components/ui/ui-text-fit'

export default {
	name: 'trivia-question',
	data() {
		return {
			loading: true
		}
	},
	props: {
		question: { default: false },
		no: { default: 1 },
		total: { default: 1 }
	},
	computed: {
		current_question_text() {
			let text = this.question.contentTexts.find((q) => {
				if (q.tags) return q.tags.includes('question')
				return false
			})
			if (text && text.html) return text.html
			return ''
		},
		current_question_type() {
			if (this.question.clientMedias && this.question.clientMedias.length > 1) return 'carousel'
			return 'text'
		},
		carousel_slides() {
			const { question: { contentTexts = [], clientMedias = [] } } = this
			const search = what => object => ((object || {}).tags || []).includes(what)
			return [
				...contentTexts.filter(search('question')),
				...clientMedias.filter(search('question_carousel'))
			]
		}
	},
	watch: {
		question() {
			this.init()
		}
	},
	methods: {
		init() {
			this.images = []
			if (this.current_question_type == 'text') {
				this.set_loaded(false)
			} else {
				this.set_loaded(true)
			}
		},
		set_loaded(loading) {
			this.loading = loading
			if (!loading) setTimeout(() => { this.$emit('loaded') }, 500)
		}
	},
	mounted() {
		this.init()
	},
	components: { Carousel, uiTextFit }
}
</script>

<style lang="stylus" scoped>
@import '../../../assets/css/variables'

.trivia-question
	height 100%
	color #fff
	.bg
		position absolute
		left 0
		top 0
		right 0
		bottom 0
		background url('../../../assets/img/province_map.svg') center center no-repeat
		background-size cover
		&:after
			background linear-gradient(to bottom, #1CB5E0, #28D0F9)
			content ''
			position absolute
			left 0
			top 0
			right 0
			bottom 0
			opacity 0.95
	.top
		height 64px
		flex center center
		flex-direction column
		// padding 16px 0
		text-align center
		font-size 2.4rem
		font-weight 400
		.top-title
			width 100%
	.content
		height calc(100% - 64px)
		padding 16px 0 16px 0
		display flex
		align-items center
		justify-content center
		text-align center
		font-size 2.2rem
		line-height 28px
		> .text
			height 100%
			padding 0 32px
			display flex
			align-items center
			justify-content center

</style>
