<template>
	<div v-if="city" class="world-map-pin" :class="[city.slug, { locked: !unlocked, unlockable: unlockable }]">
		<div class="city-icon" v-if="city_icon"><img :src="getCityPin({ city })"></div>
		<div v-if="criteria" class="tooltip">{{ criteria }} <ui-icon :tags="[ 'resource', 'lys', 'white' ]" :size="12" /></div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import {getValueOnPath} from "@/js/utils/utils"
import { getLockStatus, getMedalWon } from '@/js/utils/achievements'
import {getPointsByCity} from "../../js/utils/achievements";

export default {
	name: 'world-map-pin',
	data() {
		return {

		}
	},
	props: {
		city: { default: null }
	},
	computed: {
		...mapGetters([ 'user', 'user_gold' ]),
		city_icon() {
			return this.$medias.find({
				clientMedias: this.city.clientMedias,
				tags: ['city_icon']
			})
		},
		criteria() {
			const { city: { clientCriterias }} = this
			return getPointsByCity({ clientCriterias })
		},
		unlocked() {
			if (!this.city.clientCriterias.length) return true
			let criteria = this.city.clientCriterias.find((c) => { return c.action == 'spend' && c.clientLootItem && c.clientLootItem.countable  && c.clientLootItem.slug == 'gold' })
			if (criteria && criteria.id && (getValueOnPath(this, 'user.achievement.criteriasAchieved') || []).find(achieve => achieve.criteriaId === criteria.id))
				return true
			return false
		},
		unlockable() {
			if (!this.unlocked) {
				let criteria = this.city.clientCriterias.find((c) => {
					return c.action == 'spend' && c.clientLootItem && c.clientLootItem.countable && c.clientLootItem.slug == 'gold'
				})
				if (criteria && criteria.amount <= this.user_gold) return true
			}
			return false
		}
	},
	methods: {
		getCityPin({ city }) {
			if (!city ) return null
			let { slug, clientMedias } = city
			if (!slug) return null
			const { user } = this
			if (!user) return null
			slug = slug.toLowerCase()
			const searchBy = ['medal', slug]
			let extra = getMedalWon({ searchBy, user })
			if (!extra) extra = getLockStatus({ city, user })
			const tags = [
				'city_icon',
				slug,
				extra
			]
			return this.$medias.find({
				clientMedias,
				tags
			})
		},
		has_gold_medal(game) {
			let criteria = game.clientCriterias.find((c) => {
				return c.action == 'presence' && this.user.achievement.criteriasAchieved.find((achieved) => { return achieved.criteriaId == c.id })
			})
			if (criteria) return true
			return false
		}
	},
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'


.world-map-pin
	position absolute
	left 20%
	top 20%
	width 64px
	height 64px
	cursor pointer
	&.montreal
		left 35%
		top 70%
	&.quebec
		left 47%
		top 49%
	&.gatineau
		left 11%
		top 81%
	&.sherbrooke
		left 48%
		top 70%
	&.lac-st-jean
		left 42%
		top 34%
	&.fermont
		left 52%
		top 15%
  &.gaspe
		left 67%
		top 48%
	&.locked
		.tooltip
			display flex
			background-color lighten(#444f54, 50%)
			color alpha(#fff, 50%)
			&:before
				border-color @background-color transparent transparent transparent
			.ui-icon
				opacity 0.5
	&.locked.unlockable
		.tooltip
			color #fff
			background-color #444f54
			&:before
				border-color @background-color transparent transparent transparent
			.ui-icon
				opacity 1
	.city-icon
		width 100%
		height 100%
		display flex
		align-items center
		justify-content center
		opacity 1
		> img
			width 100%
			height 100%
			object-fit contain
	.tooltip
		position absolute
		left 50%
		top -28px
		height 24px
		transform translateX(-50%)
		line-height @height
		background-color #6C6C6C
		box-shadow 0 0 0 1px alpha(#fff, 10%)
		border-radius 24px
		padding 0 10px
		color #fff
		white-space nowrap
		font-weight 700
		align-items center
		justify-content center
		display none
		&:before
			content ''
			position absolute
			left calc(50% - 6px)
			bottom -6px
			width 0
			height 0
			border-style solid
			border-width 6px 6px 0 6px
		.ui-icon
			margin 0 0 0 4px
			opacity 1

</style>
