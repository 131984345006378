<template>
	<div v-if="city" class="city-badge" :style="{ 'background-image': 'url(' + badge_locked_icon + ')' }">
	</div>
</template>

<script>
	export default {
		name: 'city-badge',
		data() {
			return {}
		},
		props: {
			city: Object,
			unlocked: { default: false }
		},
		computed: {
			badge_locked_icon() {
				const status = this.unlocked ? 'unlocked' : 'locked'
				const { slug } = this.city
				return this.$medias.find({
					tags: ['badge', slug, status]
				})
			}
		}
	}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'

.city-badge
	background center center no-repeat
	background-size contain

</style>
