<template>
	<div v-if="question" class="learn-more" :class="side_hover" :style="{ 'background-color': isLastQuestion || backgroundStatus ? 'dark' : 'transparent' }">
		<!-- <div v-if="isLastQuestion || backgroundStatus" class="bg"></div> -->
		<div class="side left" @mouseenter="side_hover = 'side-hover-left'" @mouseleave="side_hover = ''" v-hammer:tap="()=> showLearnMoreContent()">
			<div class="bg"></div>
			<div class="content">
				<div class="text">
					<div class="line"><span><span>Le</span></span></div>
					<div class="line"><span><span>saviez-</span></span></div>
					<div class="line"><span><span>vous?</span></span></div>
				</div>
				<div class="arrow"></div>
			</div>
			<div class="bg tap-area"></div>
		</div>
		<div class="side right" @mouseenter="side_hover = 'side-hover-right'" @mouseleave="side_hover = ''" v-hammer:tap="()=> close()">
			<div class="bg"></div>
			<div class="content">
				<div class="text">
					<template
						v-if="isLastQuestion()"
					>
						<div class="line"><span><span>Bilan</span></span></div>
					</template>
					<template
						v-else
					>
						<div class="line"><span><span>Prochaine</span></span></div>
						<div class="line"><span><span>question</span></span></div>
					</template>
				</div>
				<div class="arrow"></div>
			</div>
			<div class="bg tap-area"></div>
		</div>
		<div v-show="loading" class="loading"></div>
	</div>
</template>

<script>
import UiTextContent from '@/components/ui/ui-text-content'

export default {
	name: 'learn-more',
	data() {
		return {
			loading: true,
			side_hover: '',
			backgroundStatus: true,
			questionId: null,
			theLastQuestion: false
		}
	},
	props: [ 'question', 'lastQuestion', 'currentLearnMore' ],
	watch: {
		theLastQuestion() {
			this.backgroundStatus = true
		},
		questionId() {
			this.backgroundStatus = true
		}
	},
	methods: {
		isLastQuestion() {
			this.$data.theLastQuestion = this.$props.lastQuestion
			return this.$data.theLastQuestion
		},
		close() {
			this.backgroundStatus = this.$data.theLastQuestion
			this.$nextTick(() => {
				this.$emit('next')
			})
		},
		// close_and_next() {
		// 	this.$data.isShowLearnMoreContent = false
		// 	this.close()
		// },
		showLearnMoreContent() {
			this.backgroundStatus = false
			this.$emit('showLearnMoreContent')
			this.$emit('close')
		}
	},
	mounted() {
		setTimeout(() => {
			this.loading = false
		}, 500)
	},
	components: { UiTextContent }
}
</script>

<style lang="stylus" scoped>
@import '../../../assets/css/variables'

#app.mobile
	.learn-more
		border-radius 0

.learn-more
	absolute 0
	// background-color dark
	overflow hidden
	-webkit-mask-image -webkit-radial-gradient(white, black)
	border-radius 32px
	transition 0.5s easeOutQuart

	&.learn-more-enter, &.learn-more-leave-to
		// opacity 0
		.side
			&.left
				transform translateX(-150%)
			&.right
				transform translateX(150%)

	&.side-hover-left
		.side .bg
			transform rotate(10deg) translateX(10%)
		.side.left
			.bg:before
				background-position -150px 0
				// opacity 0.1
			.content
				.text
					transform translateY(-8px)
				.arrow
					transform translateX(-8px) scaleX(-1)
		.side.right .bg:before
			background-position -470px 53px

	&.side-hover-right
		.side .bg
			transform rotate(10deg) translateX(-10%)
		.side.left .bg:before
			background-position 50px 0
		.side.right
			.bg:before
				background-position -270px 53px
				// opacity 0.1
			.content
				.text
					transform translateY(-8px)
				.arrow
					transform translateX(8px)

	// > .bg
	// 	absolute 0
	// 	background url('../../../assets/img/province_map.svg') center center no-repeat
	// 	background-size cover
	// 	opacity 0.1
	.side
		position absolute
		top 0
		bottom 0
		width 50%
		transition 0.5s easeOutQuart
		&.left
			left 1px
			.bg
				left -51%
				background-color #7ED5FF
				&:before
					background-position -50px 0
			.content
				left 0
				top 0
				flex flex-start flex-start
				flex-direction column
				.arrow
					transform scaleX(-1)
				.text
					.line > span:before
						background-color #4a91b4
		&.right
			right 1px
			.bg
				right -51%
				background-color #6C94C2
				&:before
					background-position -370px 53px
			.content
				right 0
				bottom 0
				flex flex-end flex-end
				flex-direction column
				.text
					text-align right
					.line > span:before
						background-color #8bc3df
		.bg
			position absolute
			top -15%
			bottom -15%
			width 150%
			transform rotate(10deg)
			cursor pointer
			// opacity 0.75
			transition 0.25s easeOutQuart
			&:before
				content ''
				absolute 0
				background url('../../../assets/img/province_map.svg') center center no-repeat
				background-size auto 100%
				opacity 0.05
				perspective 1000px
				will-change opacity, background-position
				transition background-position 0.25s easeOutQuart, opacity 0.25s easeOutQuart
			&.tap-area
				opacity 0
				z-index 2
		.content
			position absolute
			height 50%
			width 100%
			padding 32px
			.text
				color #fff
				font-size 4rem
				font-weight 700
				white-space nowrap
				transition 0.25s easeOutQuart
				.line
					display block
					> span
						&:before
							content ''
							position absolute
							left -2%
							right -2%
							top 45%
							bottom 10%
			.arrow
				width 64px
				height 64px
				margin 16px 0 0 0
				background url('../../../assets/img/double-arrow-light.svg') center center no-repeat
				background-size 100%
				transition 0.25s easeOutQuart

	.learn-more-content
		absolute 0
		background-color #fff
		z-index 2
		transition transform 0.25s easeOutQuart
		color #237096
		&.learn-more-content-enter, &.learn-more-content-leave-to
			transform translateX(-100%)
		> .scroll
			height 100%
			> div
				padding 32px
		.bg
			position absolute
			left -25%
			right 0
			top -40px
			width 150%
			height 250px
			background-color #7ED5FF
			transform rotate(-10deg)
		.title
			margin 0 0 16px 0
			font-size 4rem
			font-weight 700
			color #fff
			line-height 48px
			> span
				text-decoration none
				background linear-gradient(to top, #4A91B4 0%, #4A91B4 50%, transparent 50.01%) no-repeat left bottom / 0 100%
				background-size 100% 100%
		.image
			text-align center
			margin 0 0 16px 0
			img
				max-width 100%
				height auto
				margin 0 auto
		>>> .ui-text-content
			margin 0 0 32px 0
			font-size 2.4rem
			line-height 32px
		.buttons
			padding 32px 0
			border-top 4px solid #237096
			.button
				flex center center
				width 100%
				height 48px
				border-radius 16px
				background #4A91B4
				box-shadow 0px 3px 6px alpha(#000, 50%)
				cursor pointer
				color #fff
				font-size 2.4rem
				.arrow
					width 24px
					height 24px
					margin 0 0 0 16px
					background url('../../../assets/img/double-arrow-light.svg') center center no-repeat
					background-size 100%

	.loading
		absolute 0
		z-index 2

</style>
