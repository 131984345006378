<template>
	<div class="backpack">
		<div class="backpack-bg" v-hammer:tap="()=> close()"></div>
		<div class="backpack-content">
			<div class="scroll">
				<div class="content">

					<div class="bg" ref="backpack" :style="{ 'background-image': 'url(' + backpack_media + ')' }"></div>

					<div class="stamps">
						<div class="stamps-content">
							<div v-for="city in world.cities" class="stamp" :class="city.slug" :key="city.id" v-hammer:tap="()=> goto_city(city)">
								<backpack-city-pin :city="city" />
							</div>
						</div>
					</div>

					<div class="user-tag">
						<div class="user-tag-content">
							<div class="pass-top"></div>
							<div class="pass-content">
								<p class="label">Identification :</p>
								<p class="value">
									<span v-if="user.profile && user.profile.surname">{{ user.profile.surname }}</span>
									<span v-else>Anonyme</span>
								</p>
								<div class="button-logout" v-hammer:tap="() => logout()">Déconnexion</div>
							</div>
							<div class="pass-bottom"></div>
						</div>
					</div>

					<div class="close" v-hammer:tap="()=> close()"></div>
				</div>
			</div>

		</div>

	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import BackpackCityPin from '@/components/world/backpack-city-pin'
import { getValueOnPath } from '@/js/utils/utils'

export default {
	name: 'backpack',
	data() {
		return {

		}
	},
	computed: {
		...mapGetters([ 'world', 'user' ]),
		backpack_media() {
			return this.$medias.find({ tags: [ 'backpack' ] })
		},
	},
	methods: {
		is_unlocked_city(city) {
			if (!city.clientCriterias.length) return true
			let criteria = city.clientCriterias
				.find((c) => { return c.action == 'spend' && c.clientLootItem && c.clientLootItem.countable  && c.clientLootItem.slug == 'gold' })
			if (
				criteria
				&& criteria.id
				&& (getValueOnPath(this, 'user.achievement.criteriasAchieved') || [])
					.find(achieve => achieve.criteriaId === criteria.id)
			) return true
			return false
		},
		goto_city(city) {
			this.$router.push({ name: 'city', params: { city_slug: city.slug } })
		},
		logout() {
			this.$store.dispatch('removeUser')
			this.$store.dispatch('removeAccessToken')
			this.$router.push({ name: 'start' })
		},
		close() {
			this.$router.push({ name: 'world' })
		}
	},
	components: { BackpackCityPin }
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'

#app.mobile
	.backpack
		.backpack-content
			.content
				width (628px / 1.3)
				height (920px / 1.3)
				.stamps .stamps-content .stamp
					transform scale(0.8)
					&.gatineau
						top 6%
						left 15%
					&.montreal
						top 26%
						left 15%
					&.sherbrooke
						top 28%
			.user-tag
				top 28px
				right 76px
			.close
				top -20px
				right 16%

.backpack
	&.backpack-enter, &.backpack-leave-to
		opacity 0
		.backpack-content
			transform translate(0, -32px)

.backpack
	position fixed
	left 0
	top 0
	bottom 0
	right 0
	// height 100%
	// width 100%
	flex center center
	transition 0.25s easeOutQuart
	overflow-y auto
	overflow-x hidden
	padding 40px 0
	.backpack-bg
		position fixed
		left 0
		top 0
		bottom 0
		right 0
		background-color alpha(#000, 50%)
	.backpack-content
		// position absolute
		// left 50%
		// top 50%
		width 100%
		height 100%
		// absolute 0
		flex center center
		// transform translate(-50%, -50%)
		// width (628px / 1.15)
		// height (920px / 1.15)
		transition 0.25s easeOutQuart
		pointer-events none
		.scroll
			pointer-events all
			.content
				width (628px / 1.15)
				height (920px / 1.15)
				.bg
					absolute 0
					background top left no-repeat
					background-size contain

				.user-tag
					position absolute
					right 96px
					top 32px
					width 150px
					background url(../../assets/img/user-tag.svg) top center no-repeat
					// background-color #FCA76D
					background-size 100% auto
					border-radius 4px
					padding 40px 4px 4px 4px
					transform-origin top center
					animation swing 1s linear
					.user-tag-content
						// padding 12px 12px 8px 12px
						background-color #F6F6F6
						border-radius 4px
						.pass-top
							width 100%
							height 52px
							background url(../../assets/img/pass-top.svg) center bottom no-repeat
							background-size calc(100% - 16px)
						.pass-content
							padding 8px
							.label
								margin 0 0 4px 0
								color #ccc
								text-transform uppercase
								font-size 1.4rem
								font-weight 500
								line-height 1em
								user-select none
							.value
								margin 0 0 8px 0
								padding 4px 8px
								background-color #fff
								font-size 1.4rem
								line-height 1em
							.button-logout
								background-color #D85E53
								border-radius 40px
								height 24px
								color #fff
								font-size 1.3rem
								line-height @height
								white-space nowrap
								font-weight 500
								text-align center
								text-transform uppercase
								cursor pointer
								user-select none
								&:hover
									background-color darken(#D85E53, 10%)
								&:active
									background-color darken(#D85E53, 20%)
									transform translateY(1px)
						.pass-bottom
							display none
							width 100%
							height 48px
							background #CEE5F3 url(../../assets/img/pass-bottom.svg) center center no-repeat
							background-size calc(100% - 16px)


				.stamps
					height 100%
					.stamps-content
						width 100%
						height 100%
						.stamp
							position absolute
							left 0
							top 0
							cursor pointer
							&.gatineau
								left 12%
								top 7%
							&.montreal
								left 10%
								top 25%
							&.sherbrooke
								left 52%
								top 28%
							&.quebec
								left 12%
								top 58%
							&.lac-st-jean
								left 46%
								top 57%
							&.gaspe
								left 15%
								top 77%
							&.fermont
								left 50%
								top 76%
							>>> .city-badge
								width 150px
								height 150px
								margin 16px 8px
								transition 0.25s easeOutQuart

		.close
			position absolute
			right 4%
			top 0
			width 40px
			height 40px
			background dark_blue url(../../assets/img/close.svg) center center no-repeat
			background-size 16px auto
			border-radius 50%
			border 4px solid yellow_light
			cursor pointer
			&:active
				transform translateY(1px)



@media (max-height: 800px)
	// smaller()
	.backpack
		.backpack-content
			flex center flex-start
			padding 40px 0
			// transform translate(-50%, -50%)





@keyframes swing
	0%, 100%
		transform rotate(0)
	25%
		transform rotate(-4deg)
	50%
		transform rotate(2deg)
	75%
		transform rotate(0deg)



</style>
