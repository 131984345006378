<template>
	<div class="end-game">
		<div class="end-game-content">

			<div class="end-game-title">
				<div class="text">{{ get_text('title') }}</div>
				<div class="underline"></div>
			</div>

			<transition name="scores">
				<div v-show="step >= 1" class="scores" :class="game_type">
					<div class="score player">
						<div class="avatar"></div>
						<div class="nb">{{ get_score('player_score') }}</div>
					</div>
					<div class="score opponent">
						<div class="nb">{{ get_score('opponent_score') }}</div>
						<div class="avatar" v-html="opponent_avatar"></div>
					</div>
				</div>
			</transition>

			<div class="medals">
				<transition name="medal-shadow">
					<div v-show="step >= 1" class="medal shadow">
						<img :src="medalShadow">
					</div>
				</transition>
				<transition name="medal">
					<div class="medal" v-if="medal && step >= 2" :class="[ medal, { 'medal-visible': medal } ]">
						<img :src="medalWon">
						<!-- <div class="shadow"></div> -->
						<div class="icon"></div>
					</div>
				</transition>
			</div>

			<div class="results">
				<transition name="step" mode="out-in">
					<div v-if="step === 3" class="step rewards" key="rewards">
						<div v-if="medal" class="text">Nouvelle médaille!</div>
						<div v-if="nb_diamonds" class="diamonds">
							<div class="label"><ui-icon :tags="[ 'resource', 'lys', 'white' ]" :size="40" /></div>
							<div class="value"><animated-number :value="nb_diamonds" :formatValue="format_nb_diamonds" :duration="1000" /></div>
						</div>
					</div>

					<div v-if="step >= 4" class="step times" key="times">
						<div class="time">
							<div class="label">Temps écoulé</div>
							<div class="value">{{ format_time(game_stats.time) }}</div>
						</div>
						<!-- <div class="time">
							<div class="label">Meilleur temps</div>
							<div class="value">{{ format_time(game_stats.time) }}</div>
						</div> -->
					</div>
				</transition>
			</div>

			<transition name="buttons">
				<div v-show="step >= 5" class="buttons">
					<div v-hammer:tap="retry" class="button">
						<span v-if="!loading">Réessayer</span>
						<span v-if="loading" class="loading"><ui-icon name="button-loader" :size="40" /></span>
					</div>
					<div v-hammer:tap="back" class="button">Retour à la carte</div>
				</div>
			</transition>

		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getValueOnPath } from '@/js/utils/utils'
import AnimatedNumber from 'animated-number-vue'
import { sumBy } from 'lodash'
// import { getMedalWon } from '../../js/utils/achievements'

export default {
	name: 'end-game',
	data() {
		return {
			loading: false,
			step: 0,
			remaning_time: 0,
			nb_good_answers: 0,
			nb_bad_answers: 0,
			nb_diamonds: 0,
			medal: null,
			buttons_visible: false
		}
	},
	props: {
		game_type: { default: null }
	},
	filters: {
		br(text) {
			return text.split(' ').join('\n')
		}
	},
	computed: {
		...mapGetters([ 'game', 'user', 'world' ]),
		medalWon() {
			return this.$medias.find({ tags: [ 'medal', this.medal ] })
		},
		medalShadow() {
			return this.$medias.find({ tags: [ 'medal', 'empty' ] })
		},
		game_stats() {
			let marks = getValueOnPath(this.game, 'progression.marks')
			if (marks && marks.length > 1) return marks[marks.length-2].stats
			return false
		},
		opponent_avatar() {
			const city = getValueOnPath(this, 'world.current.city')
			if (city && city.slug) {
				const tags = [ city.slug, 'avatar' ]
				return this.$medias.find({ tags })
			}
			return null
		},
	},
	methods: {
		get_score(type) {
			return this.game[type]
		},
		get_values() {
			this.remaning_time = 60 - this.game_stats.time
			this.nb_good_answers = sumBy(this.game_stats.totalPerScore, (s) => { if (s.score === 1) return s.total })
			this.nb_bad_answers = sumBy(this.game_stats.totalPerScore, (s) => { if (s.score === 0) return s.total })
			this.nb_diamonds = ((getValueOnPath(this, 'game.loot_rewards') || [])
				.filter(x => getValueOnPath(x, 'reward.clientLootItem.slug') === 'gold')
				.pop() || {}).amount || 0
			// setTimeout(() => {
			const medals = ['gold', 'silver', 'bronze']
			this.medal = this.game.loot_rewards
				.filter(x => x.amount === 1)
				.filter(x => x.reward.clientLootItem.tags.includes('medal'))
				.map(x => x.reward.clientLootItem.tags)
				.map(tags => tags.reduce((a, tag) => medals.includes(tag) ? tag : a, null))
				.reduce((a, medal) => {
					a[medals.indexOf(medal)] = medal
					return a
				}, [])
				.reduce((a, medal) => (a ? a : (medal ? medal : a)), null)
			// }, 1500)
		},
		get_text(tag) {
			let texts = getValueOnPath(this.game, 'step.contentTexts')
			if (texts) return (texts.find((t) => { return t.tags.includes(tag) }) || { html: "" }).html
			return ''
		},
		format_time(seconds) {
			const format = val => `0${Math.floor(val)}`.slice(-2)
			// const hours = seconds / 3600
			const minutes = (seconds % 3600) / 60
			return [minutes, seconds % 60].map(format).join(':')
		},
		format_nb_answers(value) {
			if (value > 999) return value.toFixed(0)
			return ('00' + value.toFixed(0)).slice(-3)
		},
		format_nb_diamonds(value) {
			return '+ ' + this.format_nb_answers(value)
		},
		retry() {
			if (!this.loading) {
				this.loading = true
				this.$store.dispatch('launch_game', { game_id: this.$route.params.game_id })
			}
		},
		back() {
			this.$router.push({ name: 'world' })
		},
		launch_tuto(tuto_name) {
			const city = getValueOnPath(this, 'world.current.city')
			const animations = this.$medias.findAllByTags({ tags: [ 'character_animation', city.slug ] })
			this.$store.commit('EDIT_APP_STATE', { tuto: { name: tuto_name, animations } })
		}
	},
	mounted() {
		this.step = 0
		this.get_values()

		let step_durations = [
			1000, // score
			500, // médaille
			1000, // récompenses
			2000, // temps
			1000 // boutons
		]
		if (!this.medal) step_durations[1] = 0
		if (!this.medal && !this.nb_diamonds) {
			step_durations[2] = 0
			step_durations[3] = 0
		}

		let delay = 0
		step_durations.forEach((duration, index) => {
			if (duration > 0) {
				delay += duration
				setTimeout(() => {
					this.step = index + 1

					if (index === step_durations.length - 1 && this.game_type) {
						const tuto_name = 'end_' + this.game_type + '_game'
						const user_tuto = this.user.achievement.tutorialJournal.find((j) => j.tutorialId === tuto_name)
						if (!user_tuto || user_tuto.status !== 'done') {
							this.launch_tuto(tuto_name)
						} else {
							const slug = this.game.player_score > this.game.opponent_score ? 'win_game' : this.game.player_score < this.game.opponent_score ? 'lose_game' : 'draw_game'
							this.launch_tuto(slug)
						}
					}
				}, delay)
			}

		})

	},
	components: { AnimatedNumber }
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'


#app.mobile
	.end-game
		border-radius 0

// .end-game
// 	&.end-game-enter-active, &.end-game-leave-active
// 		transition 1s easeOutQuart
// 	&.end-game-enter, &.end-game-leave-to
// 		opacity 0
// 		transform translateY(-100%)
// 		// .end-game-content
// 		// 	> .line
// 		// 		transform translateY(-8px)
// 		// 		opacity 0


.end-game
	position absolute
	left 0
	top 0
	right 0
	bottom 0
	border-radius 32px
	overflow hidden
	color #fff

	.end-game-content
		height 100%
		padding 32px
		flex flex-start center
		flex-direction column

		.end-game-title
			flex center center
			flex-direction column
			width 100%
			margin 0 0 32px 0
			font-size 4.8rem
			text-align center
			.underline
				height 4px
				width 100%
				background-color #fff
				border-radius 2px
				animation underline 1.5s easeOutQuart forwards

		.scores
			width 100%
			height 64px
			flex space-between center
			font-size 4rem
			line-height @height
			transition 0.5s easeOutQuart
			&.scores-enter
				transform translateY(-8px)
				opacity 0
			&:before
				content '•'
				position absolute
				left 50%
				top 0
				transform translateX(-50%)
			.score
				height 100%
				flex center center
				&.player
					.avatar
						background url(../../assets/img/avatars/player.svg) center center no-repeat
						background-size contain
					.nb
						text-align left
						padding-left 8px
				&.opponent
					.avatar
						background url(../../assets/img/avatars/opponent.svg) center center no-repeat
						background-size contain
					.nb
						text-align right
						padding-right 8px
				.nb
					width 96px
					height 64px
					text-align center
					white-space nowrap
				.avatar
					height 64px
					width 64px
					>>> svg
						width 100%
						height 100%

		.line
			flex center center
			width 100%
			margin 0 0 16px 0
			height 64px
			transition 0.5s easeOutQuart
			for i in 0..5
				&:nth-child({i})
					transition-delay (0.1s * i)
			.label
				display inline-block
				flex flex-end center
				width 50%
				padding 0 8px
				text-align right
				font-size 2.4rem
				line-height 24px
			.value
				display inline-block
				flex flex-start center
				width 50%
				padding 0 8px
				font-family 'MonoSpatial', monospace
				font-size 4rem
				line-height 40px

		.medals
			width 200px
			height 200px
			margin 24px 0
			.medal
				absolute 0
				width 100%
				height 100%
				flex center center
				transition 0.3s cubic-bezier(0.6, 0.04, 0.98, 0.335)
				background-size auto 100%
				&.medal-enter-active, &.medal-leave-active
					transition 0.5s easeInQuart
				&.medal-enter, &.medal-leave-to
					opacity 0
					transform translateY(-50%) scale(3)
				&.medal-shadow-enter-active
					transition 0.5s easeOutQuart
				&.medal-shadow-enter
					transform translateY(-8px)
					opacity 0
				&.shadow
					opacity 0.5
					transform translateY(4px)
				img
					height 100%
					width auto

		.results
			height 96px
			margin 0 0 32px 0
			flex center center
			flex-direction column
			width 100%
			.step
				transition 0.5s easeOutQuart
				&.step-enter
					transform translateY(-8px)
					opacity 0
				&.step-leave-to
					transform translateY(8px)
					opacity 0
			.rewards
				text-align center
				.text
					margin 0 0 8px 0
					font-size 2rem
				.diamonds
					flex center center
					.label
						margin 0 8px 0 0
					.value
						font-size 2.4rem
			.times
				flex center center
				flex-direction column
				width 250px
				font-size 2rem
				.time
					flex center center
					height 32px
					width 100%
					line-height @height
					.label
						width calc(75% - 24px)
						margin 0 24px 0 0
						text-align right
					.value
						width 25%
						font-weight 500

		.buttons
			width 100%
			flex center center
			flex-direction column
			transition 0.5s easeOutQuart
			&.buttons-enter, &.buttons-leave-to
				transform translateY(8px)
				opacity 0
			.button
				width 280px
				height 56px
				flex center center
				margin 0 0 16px 0
				border-radius 8px
				background-color alpha(#000, 15%)
				box-shadow 0 4px 4px 0 alpha(#000, 25%)
				text-align center
				font-size 2.0rem
				text-transform uppercase
				cursor pointer
				transition 0.1s easeOutQuart
				&:last-child
					margin 0
				&:hover
					background-color alpha(#000, 10%)
					box-shadow 0 6px 6px 0 alpha(#000, 25%)
					transform translateY(-2px)
				&:active
					background-color alpha(#000, 20%)
					box-shadow 0 2px 2px 0 alpha(#000, 25%)
					transform translateY(2px)
				> span
					absolute 0
					flex center center
					&.loading
						cursor progress



@keyframes underline
	0%
		width 0
	100%
		width 100%



@media (max-height: 700px)
	.end-game .end-game-content
		padding 16px 32px
		.scores
			height 48px
			line-height @height
			.score
				font-size 2.4rem
				.avatar
					width @height
					height @height
				.nb
					height @height
		.medals
			margin 16px 0 0 0
			height 140px
			width 140px
		.results
			margin 0 0 16px 0


</style>
