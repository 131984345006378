import { render, staticRenderFns } from "./ui-text-fit.vue?vue&type=template&id=e91a0f28&scoped=true&"
import script from "./ui-text-fit.vue?vue&type=script&lang=js&"
export * from "./ui-text-fit.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e91a0f28",
  null
  
)

export default component.exports