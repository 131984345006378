<template>
	<div>
		<slot />
	</div>
</template>

<script>
import { textFitInContainer } from '@/js/utils/utils'

export default {
	name: "ui-text-fit",
	props: [ 'elementClass', 'fontSize', 'lineHeight' ],
	methods: {
		textFit() {
			const { elementClass, fontSize, lineHeight } = this
			const elements = document.getElementsByClassName(elementClass)
			textFitInContainer({ elements, fontSize, lineHeight })
		}
	},
	mounted() {
		this.textFit()
	},
	updated() {
		this.textFit()
	},
}
</script>

<style scoped lang="stylus">

</style>
