<template>
	<div class="world">

		<world-map @load="set_world_loaded()" />

		<transition name="user-button" appear>
			<ui-button class="user-button passport" color="dark-blue" v-hammer:tap="()=> show_backpack()">Sac à dos</ui-button>
		</transition>
		<div v-if="isBackpackNotification" class="backpack-notification"></div>

		<div class="bottom-right">
			<transition name="user-button" appear>
				<ui-button class="user-button settings" color="dark-blue" v-hammer:tap="()=> show_settings()"><ui-icon name="i-info" :size="20" /></ui-button>
			</transition>
			<transition name="user-button" appear>
				<ui-button class="user-button money" color="dark-blue">{{ user_gold }} <ui-icon :tags="[ 'resource', 'lys', 'white' ]" /></ui-button>
			</transition>
		</div>
		<!-- <transition name="user-button" appear>
			<ui-button class="user-button money" color="dark-blue">{{ user_gold }} <ui-icon :tags="[ 'resource', 'lys', 'white' ]" /></ui-button>
		</transition> -->
		<!-- <transition name="user-button" appear>
			<ui-button class="user-button settings" color="dark-blue" v-hammer:tap="()=> show_settings()"><ui-icon name="settings" :size="24" /></ui-button>
		</transition> -->

		<transition name="backpack" appear>
			<backpack v-if="backpack_visible" />
		</transition>

		<transition name="save-progression" appear>
			<save-progression v-if="save_progression_visible" />
		</transition>

		<transition name="city" appear>
			<city v-if="world.current.city && !game.progression" :city="world.current.city" />
		</transition>

	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import City from '@/components/world/city'
import Backpack from '@/components/world/backpack'
import SaveProgression from '@/components/world/save-progression'
import WorldMap from '@/components/world/world-map'

export default {
	name: 'world',
	data() {
		return {
			isBackpackNotification: false
		}
	},
	computed: {
		...mapGetters([ 'game', 'world', 'user', 'user_gold' ]),
		backpack_visible() {
			return this.$route.name === 'backpack'
		},
		save_progression_visible() {
			return this.$route.name === 'backpack-save'
		}
	},
	watch: {
		'user.achievement.rewardsAchieved': {
			handler: function(newValue, oldValue) {
				this.isBackpackNotification = (newValue.length > 0)
			},
			deep: true
		}
	},
	methods: {
		show_backpack() {
			if (this.user.email) {
				this.isBackpackNotification = false
				this.$router.push({ name: 'backpack' })
			} else {
				this.$router.push({ name: 'backpack-save' })
			}
		},
		show_settings() {
			this.$store.commit('EDIT_APP_STATE', { settings_visible: true })
		},
		reset_user() {
			this.$store.dispatch('reset_user')
		},
		set_world_loaded() {
			this.$store.commit('EDIT_APP_STATE', { main_loader: false })

			if (localStorage.getItem('jeuRapide')) {
				this.$router.push({ name: 'game', params: { city_slug: 'gatineau', game_id: '5e541ea249ccbc0006c5c822' } })
			} else if (!this.user.email) {
				const animations = this.$medias.findAllByTags({ tags: [ 'character_animation', 'bus_driver' ] })
				this.$store.commit('EDIT_APP_STATE', { tuto: { name: 'login', animations } })
			}


			// if (!this.backpack_visible) this.show_backpack()
		}
	},
	components: { City, Backpack, WorldMap, SaveProgression }
}
</script>

<style lang="stylus" scoped>
@import '../assets/css/variables'

.world .user-button
	&.user-button-enter-active, &.user-button-leave-active
		transition 0.25s easeOutQuart
	&.user-button-enter, &.user-button-leave-to
		opacity 0
		transform translateY(100%)


.world
	height 100%
	.bottom-right
		position absolute
		right 8px
		bottom 8px
		flex center center
		.user-button
			margin 0 8px 0 0
			&:last-child
				margin 0
	.user-button
		// position absolute
		// bottom 8px
		font-weight 700
		text-transform uppercase
		&.passport
			position absolute
			bottom 8px
			left 8px
			>>> .button
				padding-left 48px
				justify-content flex-start
			&:after
				content ''
				position absolute
				left 0
				top 0
				width (40px - 4px)
				height (40px - 4px)
				border-radius 50%
				background dark_blue url(../assets/img/backpack-little-white.svg) center center no-repeat
				background-size 17px auto
				border 2px solid #fff
		&.money
			// right 8px
			>>> .button
				justify-content flex-end
				cursor default
				&:active
					transform none
				*
					cursor default
		&.settings
			// right 120px
			>>> .button
				min-width 56px
				padding 0
				width 56px
				justify-content center
				.ui-icon
					margin 0
		>>> .button
			height 40px
			padding 0 24px
			// min-width 96px
			.ui-icon
				margin 0 0 0 8px
	.backpack-notification
		position absolute
		left 4px
		bottom 48px
		width 48px
		height 48px
		background url(../assets/img/passeport-notification.svg) bottom center no-repeat
		background-size contain
		animation bounce 1s linear infinite

@keyframes bounce
	0%, 100%
		transform none
	50%
		transform translateY(-8px)


</style>
