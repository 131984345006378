<template>
	<div class="game" :class="city_color" id="game">
		<div class="game-bg"></div>
		<div class="game-content">
			<component v-if="[ 'trivia' ].includes(game_type)" :is="game_type" @opponentScore="opponentScore" />

			<transition name="end-game">
				<end-game v-if="game_type === 'result'" :game_type="game_component" :opponent-score="opponent_score" />
			</transition>

			<div v-if="game.current && !game.current.step" class="loading-game"><img src="@/assets/img/loader.svg"></div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Trivia from '@/components/game/trivia'
import EndGame from '@/components/game/end-game'
import { getValueOnPath } from '@/js/utils/utils'

export default {
	name: 'game',
	data() {
		return {
			game_component: '',
			end_game_closable: false,
			opponent_score: 0,
		}
	},
	computed: {
		...mapGetters([ 'app', 'user', 'world', 'game' ]),
		game_type() {
			return getValueOnPath(this.game, 'step.layout')
		},
		city_color() {
			return getValueOnPath(this.world, 'current.city.document.color') || ''
			// return (this.world.current.city && this.world.current.city.document && this.world.current.city.document.color) ? this.world.current.city.document.color : ''
		}
	},
	watch: {
		game_type(newValue, oldValue) {
			this.update_game_component()
			if (newValue === 'result') {
				setTimeout(() => {
					this.end_game_closable = true
				}, 4000)
			} else
				this.end_game_closable = false
		}
	},
	methods: {
		opponentScore(score) {
			this.opponent_score = score
		},
		update_game_component() {
			if ([ 'trivia', 'matching' ].includes(this.game_type)) this.game_component = this.game_type
		},
		close_end_game() {
			if (this.game_type === 'result' && this.end_game_closable) {
				this.$router.push({ name: 'city', params: { city_slug: this.world.current.city.slug } })
			}
		}
	},
	mounted() {
		if (this.game_type) {
			this.update_game_component()
			const tuto_name = this.game_type + '_game'
			const { user: { achievement: { tutorialJournal = [] } = {} } = {} } = this
			if (tutorialJournal.length) {
				const user_tuto = tutorialJournal.find((j) => j.tutorialId === tuto_name)
				if (!user_tuto || user_tuto.status !== 'done') {
					const city = getValueOnPath(this.world, 'current.city')
					const animations = this.$medias.findAllByTags({ tags: [ 'character_animation', city.slug ] })
					this.$store.commit('EDIT_APP_STATE', { tuto: { name: tuto_name, animations } })
				}
			}
		}
	},
	destroyed() {
		// this.$store.dispatch('clear_game')
	},
	components: { Trivia, EndGame },
}
</script>

<style lang="stylus" scoped>
@import '../assets/css/variables'
@import '../assets/css/city_color'


#app.mobile
	.game
		.game-content
			border-radius 0


.game
	&.game-enter-active, &.game-leave-active
		transition 0.25s easeOutQuart
		.game-content
			transition 0.25s easeOutQuart

	&.game-enter
		opacity 0
		.game-content
			transform translateY(-40px)
	&.game-leave-to
		opacity 0
		.game-content
			transform translateY(40px)


.game
	position absolute
	left 0
	top 0
	right 0
	bottom 0
	display flex
	align-items center
	justify-content center
	&.tuto-highlight
		.game-bg
			display none

	.game-bg
		position absolute
		left 0
		top 0
		right 0
		bottom 0
		background-color alpha(#000, 75%)
	.game-content
		width 100%
		height 100%
		max-width 420px
		max-height 740px
		border-radius 32px
		box-shadow 0 0 0 4px alpha(#000, 50%), 0px 10px 6px alpha(#000, 30%)
		border-radius 32px
		.loading-game
			position absolute
			left 0
			top 0
			bottom 0
			right 0
			background linear-gradient(to bottom, #F37F7F, #CF376B)
			display flex
			align-items center
			justify-content center
			border-radius 32px
			overflow hidden
			img
				width 30%

@media (max-width: 420px)
	.game
		.game-content
			border-radius 0
</style>
