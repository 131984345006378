<template>
	<div class="view view-main">

		<world v-if="ready" @load="set_loaded" />

		<transition name="game">
			<game v-if="ready && game.progression" />
		</transition>

		<transition name="settings">
			<settings v-if="app.settings_visible" />
		</transition>

		<ui-offline-bar />
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import World from '@/components/world'
import Game from '@/components/game'
import Settings from '@/components/settings'
import UiOfflineBar from '@/components/ui/ui-offline-bar'

export default {
	name: 'view-main',
	data() {
		return {
			ready: false
		}
	},
	computed: {
		...mapGetters([ 'app', 'game' ])
	},
	methods: {
		set_loaded() {
			this.$store.commit('EDIT_APP_STATE', { main_loader: false })
		}
	},
	async mounted() {
		await this.$medias.init({ loadPrefetch: true })
		this.ready = true
		this.$medias.init({ loadPrefetch: false })
			.then(() => {})
	},
	components: { World, Game, Settings, UiOfflineBar }
}
</script>

<style lang="stylus" scoped>
@import '../assets/css/variables'



</style>
