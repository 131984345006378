<template>
	<div class="ui-text-content text-content" v-html="transformed_html"></div>
</template>

<script>

export default {
	name: 'ui-text-content',
	props: [ 'html' ],
	computed: {
		transformed_html() {
			let parser = new DOMParser();
			let htmlDoc = parser.parseFromString(this.html, 'text/html')
			let links = htmlDoc.querySelectorAll('a')

			for (let i = 0; i < links.length; i++) {
				let item = links[i]
				item.setAttribute('target', 'blank')
			}

			return htmlDoc.firstChild.innerHTML
		}
	}
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'
@import '../../assets/css/text-content'

.ui-text-content
	display block


				

</style>
