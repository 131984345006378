import {getValueOnPath} from './utils'

/**
 * Returns 'bronze', 'silver', 'gold' depending on the highest medal won
 * or null if no medal
 * @param medals
 * @param searchBy
 * @param user
 * @returns {any|void|null}
 */
export const getMedalWon = ({ medals = ['bronze', 'silver', 'gold'], searchBy, user }) => {
	const { inventory } = user
	if (!inventory) return null
	let lootItems = inventory
		.filter(x => {
			const tags = getValueOnPath(x, 'clientLootItem.tags')
			if (!tags) return false
			return tags.reduce((a, tag) => searchBy.includes(tag) ? a + 1 : a, 0) === searchBy.length
		})
		.map(x => x.clientLootItem)
	if (!lootItems.length) return null
	lootItems = lootItems
		.map(x => Object.assign(x, {
			weight: x.tags.reduce((a, tag) => medals.includes(tag)
				? medals.indexOf(tag)
				: a
				, -1)
		}))
	const lootItemTags = (lootItems.sort((a, b) => b.weight - a.weight)[0] || {}).tags
	return medals
		.filter(x => lootItemTags.includes(x))
		.pop()
}

/**
 * Returns 'locked', 'unlocked' depending on the city's status
 * @param city
 * @param user
 * @returns {*}
 */
export const getLockStatus = ({ city, user }) => {
	let { achievement: { criteriasAchieved } } = user
	criteriasAchieved = criteriasAchieved.map(x => x.criteriaId)
	let { clientCriterias } = city
	clientCriterias = clientCriterias.map(x => x.id)
	return {
		false: 'locked',
		true: 'unlocked'
	}[
	criteriasAchieved
		.reduce((a, achieved) => clientCriterias.includes(achieved) ? a + 1 : a, 0) === clientCriterias.length || false
		]
}

/**
 * Returns an array of what's in the inventory, by tags
 * @param tags
 * @param inventory
 * @returns {*}
 */
export const getInventoryByTags = ({ tags = [], inventory = [] }) => inventory
	.filter(x => (getValueOnPath(x, 'clientLootItem.tags') || [])
	.every(tag => tags.includes(tag)))

export const getPointsByCity = ({ clientCriterias = [] }) => clientCriterias.length
	? (clientCriterias.find(x => getValueOnPath(x, 'clientLootItem.slug') === 'gold') || {}).amount || 0
	: 0
