<template>
	<div class="trivia">
		<div class="trivia-content" v-if="current_question">

			<div class="question-part" id="trivia-header">
				<trivia-question :question="current_question" :no="(question_index + 1)" :total="questions.length" @loaded="start_question()" />
				<div class="question-timer">
					<div class="timer-progress" :class="{ started: timer_progress_percent > 0 }" :style="{ transform: 'translateX(-' + (timer_progress_percent) + '%)' }"></div>
				</div>
				<div class="score player">
					<div class="nb">{{ game.player_score }}</div>
					<div class="avatar"></div>
				</div>
				<div class="score opponent">
					<div class="avatar" v-html="opponent_avatar"></div>
					<div class="nb">{{ game.opponent_score }}</div>
				</div>
				<!-- <ui-timer class="timer" :time="time" :playing="timer != false" /> -->
				<!-- <div class="points">025</div> -->
			</div>
			<div class="answers-part" id="trivia-answers">
				<div class="answers" :class="{ loading: loading }">
					<trivia-answer
						v-for="answer in current_question.answers"
						:answer="answer"
						:key="answer.id"
						:show_result="show_result"
						:selected_by_player="player_answer && player_answer.id === answer.id"
						:selected_by_opponent="opponent_answer && opponent_answer.id === answer.id"
						v-hammer:tap="()=> select_answer(answer)"
					/>
				</div>
			</div>

			<div v-if="!timer" class="overlay"></div>

			<transition name="image-zoom">
				<image-zoom v-if="game.ui.zoomed_slide" :slide="game.ui.zoomed_slide" />
			</transition>

			<transition name="learn-more">
				<learn-more
					v-if="show_learn_more"
					:current-learn-more="currentLearnMore"
					:last-question="lastQuestion"
					:question="current_question"
					@next="nextStep()"
					@showLearnMoreContent="showLearnMoreContent"
				/>
			</transition>

			<transition name="learn-more-content">
				<learn-more-content
					v-if="currentLearnMore && isShowLearnMoreContent"
					:last-question="lastQuestion"
					:current-learn-more="currentLearnMore"
					@next="nextStep()"
					@close="closeLearnMoreContent()"
				/>
			</transition>

		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import TriviaQuestion from '@/components/game/trivia/trivia-question'
import TriviaAnswer from '@/components/game/trivia/trivia-answer'
import ImageZoom from '@/components/game/trivia/image-zoom'
import { getValueOnPath } from '@/js/utils/utils'
import { getLearnMore } from '@/js/utils/getters'
import LearnMore from './trivia/learn-more'
import LearnMoreContent from "./trivia/learn-more-content"

export default {
	name: 'trivia',
	data() {
		return {
			learnMore: false,
			currentLearnMore: {},
			learnMoreBase: [],
			timer: false,
			time: 0,
			question_index: 0,
			show_learn_more: false,
			show_result: false,
			player_answer: false,
			opponent_answer: false,
			selected_answer: false,
			loading: true,
			lastQuestion: false,
			isCanStartQuestion: true,
			isShowLearnMoreContent: false,
		}
	},
	computed: {
		...mapGetters([ 'app', 'game', 'user', 'world' ]),
		questions() {
			return getValueOnPath(this.game, 'step.questions')
		},
		current_question() {
			return (this.questions[this.question_index]) ? this.questions[this.question_index] : false
		},
		opponent_answers() {
			const breakdowns = getValueOnPath(this.game, 'step.simulator.breakdown')
			let ids = []
			breakdowns.forEach((breakdown) => {
				ids = [ ...ids, ...breakdown.answerIds ]
			})
			return ids
		},
		opponent_avatar() {
			const city = getValueOnPath(this, 'world.current.city')
			if (city && city.slug) {
				const tags = [ city.slug, 'avatar' ]
				return this.$medias.find({ tags })
			}
			return null
		},
		timer_progress_percent() {
			// if (this.timer) {
				const timer_total = getValueOnPath(this.current_question, 'document.timer')
				if (timer_total) return 100 - this.time * 100 / parseInt(timer_total)
				// console.log(this.time * parseInt(timer_total) / 100)
			// }
			return 0
		}
	},
	watch: {
		current_question() {
			this.loading = true
			this.clear_timer()
			if (this.current_question && this.current_question.document && this.current_question.document.timer) {
				this.time = parseInt(this.current_question.document.timer)
			}
		},
		'app.tuto'(newValue, oldValue) {
			if (!newValue && oldValue) {
				this.init_timer()
			}
		}
	},
	methods: {
		closeLearnMoreContent() {
			this.$data.isShowLearnMoreContent = false
			this.nextStep()
		},
		showLearnMoreContent() {
			this.$data.isShowLearnMoreContent = true
			setTimeout(() => {
				this.$data.show_learn_more = false
			}, 250)
		},
		start_question() {
			if (!this.isCanStartQuestion) return
			this.loading = false
			if (!this.app.tuto) this.init_timer()
		},
		init_timer() {
			this.clear_timer()
			if (this.current_question && this.current_question.document && this.current_question.document.timer) {
				this.time = parseInt(this.current_question.document.timer)
				this.timer = setInterval(this.update_timer, 1000)
			}
		},
		clear_timer() {
			if (this.timer) {
				clearInterval(this.timer)
				this.timer = false
			}
		},
		update_timer() {
			if (this.time <= 0) {
				this.select_answer(false)
			} else
				this.time--
		},
		async nextStep() {
			this.$data.isShowLearnMoreContent = false
			this.$data.show_learn_more = false
			this.question_index += 1
			if (this.questions[this.question_index]) {
				this.isCanStartQuestion = true
				this.show_result = false
				this.player_answer = false
				this.opponent_answer = false
				this.start_question()
			} else {
				this.isCanStartQuestion = false
				// Last step
				await this.$store.dispatch('complete_current_step')
				this.$emit('end')
			}
		},
		displayLearnMore({ learnMore: { html, clientMedias = [] }, lastQuestion = false }) {
			if (!html) return
			this.$data.learnMore = true
			if (clientMedias.length) {
				const { id } = clientMedias[0]
				const media = this.$medias.get({ id })
				Object.assign(this.$data.currentLearnMore, {
					html,
					media,
				})
			} else {
				Object.assign(this.$data.currentLearnMore, {
					html,
				})
			}
			const title = this.questions[this.question_index].answers
				.filter(x => x.score === 1)
				.pop()
				.contentTexts[0]
				.html
			Object.assign(this.$data.currentLearnMore, {
				title,
			})
			this.$data.lastQuestion = lastQuestion
			this.$data.show_learn_more = true
			this.isCanStartQuestion = false
		},
		async select_answer(answer) {
			this.clear_timer()
			this.player_answer = answer

			setTimeout(() => {
				this.opponent_answer = this.current_question.answers.find((a) => this.opponent_answers.includes(a.id))
			}, 800)
			setTimeout(async () => {
				this.show_result = true

				this.$store.commit('EDIT_GAME_STATE', {
					player_score: this.game.player_score + (this.player_answer ? Math.ceil(parseInt(this.player_answer.score)) : 0),
					opponent_score: this.game.opponent_score + (this.opponent_answer ? Math.ceil(parseInt(this.opponent_answer.score)) : 0)
				})

				let answers = []
				if (answer && answer.id) answers.push(answer.id)
				const time = Date.now()
				await this.$store.dispatch('trivia_send_answer', { question_id: this.current_question.id, answers_id: answers })
				const duration = 3000 - (Date.now() - time)
				setTimeout(async () => {
					const question = this.questions[this.question_index]
					const learnMore = getLearnMore({ question })
					if (learnMore) {
						const lastQuestion = this.question_index + 1 === this.questions.length
						this.displayLearnMore({ learnMore, lastQuestion })
					} else {
						this.nextStep()
					}
				}, duration)
			}, 1500)
		}
	},
	created() {
		this.$data.learnMoreBase = this.$medias.findAllByTags({
			tags: ['learn-more', 'base']
		})
	},
	components: { TriviaQuestion, TriviaAnswer, ImageZoom, LearnMore, LearnMoreContent }
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'


#app.mobile
	.trivia
		.trivia-content
			.question-part
				border-radius 0
			.answers-part
				border-radius 0

.trivia
	height 100%
	.trivia-content
		height 100%
		.question-part
			height 40%
			overflow hidden
			border-top-left-radius 32px
			border-top-right-radius 32px
			.question-timer
				position absolute
				left 0
				right 0
				top 64px
				height 6px
				width 100%
				background-color alpha(#fff, 25%)
				overflow hidden
				.timer-progress
					position absolute
					left 0
					top 0
					height 100%
					width 100%
					// background-color darken(#F16064, 5%)
					background-color #fff
					&.started
						transition transform 1s linear
			.score
				position absolute
				top 0
				height 64px
				flex center center
				color #fff
				&.player
					left 8px
					.avatar
						background url(../../assets/img/avatars/player.svg) center center no-repeat
						background-size contain
				&.opponent
					right 8px
					.avatar
						background url(../../assets/img/avatars/opponent.svg) center center no-repeat
						background-size contain
				.nb
					height 40px
					width 40px
					text-align center
					font-size 3.2rem
				.avatar
					height 40px
					width 40px
					overflow hidden
					border-radius 50%
					mask-image -webkit-radial-gradient(white, black)
					>>> svg
						width 100%
						height 100%
						mask-image -webkit-radial-gradient(white, black)
		.answers-part
			height 60%
			background-color #fff
			overflow hidden
			border-bottom-left-radius 32px
			border-bottom-right-radius 32px
			.answers
				height 100%
				display flex
				align-items center
				justify-content center
				flex-direction column
				padding 16px 32px
				&.loading
					&:after
						content ''
						position absolute
						left 0
						top 0
						right 0
						bottom 0
						background-color #fff
				.trivia-answer
					margin 8px 0
		.overlay
			position absolute
			left 0
			top 0
			right 0
			bottom 0
			cursor wait


</style>
