<template>
	<div v-if="city" class="city-badge" :class="{ unlocked }" :style="{ 'background-image': 'url(' + pinIcon + ')' }">
		<img :src="medalIcon" class="medal-icon" :class="cityMedal" alt="">
	</div>
</template>

<script>
	import {getLockStatus, getMedalWon} from "../../js/utils/achievements"
	import {mapGetters} from "vuex";
	import {getValueOnPath} from "../../js/utils/utils"

	export default {
		name: 'backpack-city-badge',
		data() {
			return {}
		},
		props: {
			city: Object,
			unlocked: { default: false }
		},
		computed: {
			...mapGetters([ 'user' ]),
			cityMedal() {
				const { city: { slug } } = this
				return slug
			},
			medalIcon() {
				const { city, user } = this
				if (!city || !user) return null
				let { slug } = city
				if (!slug) return null
				slug = slug.toLowerCase()
				const searchBy = ['medal', slug]
				let metal = getMedalWon({ searchBy, user })
				let tags
				if (metal) tags = [ 'medal', metal ]
				else {
					tags = [
						'backpack',
						'lys',
						[ 'gatineau', 'montreal', 'sherbrooke' ].includes(slug) ? 'pale' : 'dark'
					]
				}
				return this.$medias.find({ tags })
			},
			pinIcon() {
				const { city, user } = this
				const unlocked = { locked: false, unlocked: true }[getLockStatus({ user, city })]
				const { slug } = this.city
				let tags = [slug]
				const { inventory } = this.user
				const rewards = inventory.filter(x => {
					const lootTags = getValueOnPath(x, 'clientLootItem.tags')
					if (!lootTags.length) return false
					return tags.every(tag => lootTags.includes(tag))
				})
				if (rewards.length || unlocked) tags = [ 'pin', slug ]
				else tags = [ 'backpack', slug ]
				return this.$medias.find({
					tags
				})
			}
		}
	}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'

.city-badge
	background center center no-repeat
	background-size contain

.medal-icon
	position absolute
	width 40px
	right -15px
	top 10px

.gatineau
	right -5px
.montreal
	right 0
.sherbrooke
	right 0
.quebec
	right -18px
.lac-st-jean
	right -27px
.gaspe
	right -24px
.fermont
	right -20px
</style>
