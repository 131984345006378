<template>
	<div class="settings">
		<div class="bg" v-hammer:tap="close"></div>
		<div class="content">

			<div class="contribution">Avec la contribution financière de l’OQLF</div>
			<div class="logo"><img src="@/assets/img/logo-oqlf-colors.svg" alt="OQLF"></div>

			<div class="close" v-hammer:tap="close"></div>

		</div>
	</div>
</template>

<script>
export default {
	name: 'settings',
	methods: {
		close() {
			this.$store.commit('EDIT_APP_STATE', { settings_visible: false })
		}
	}
}
</script>

<style lang="stylus" scoped>
@import '../assets/css/variables'


.settings
	&.settings-enter-active, &.settings-leave-active
		transition 0.25s easeOutQuart
	&.settings-enter, &.settings-leave-to
		opacity 0
		.content
			transform translateY(-20px)


.settings
	position absolute
	left 0
	top 0
	right 0
	bottom 0
	display flex
	align-items center
	justify-content center
	.bg
		position absolute
		left 0
		top 0
		right 0
		bottom 0
		background-color alpha(#000, 50%)
	.content
		width calc(100% - 32px)
		max-width 420px
		padding 32px
		background-color #fff
		border-radius 8px
		box-shadow 0px 10px 6px alpha(#000, 30%)
		transition 0.25s easeOutQuart

		.contribution
			margin 0 0 32px 0
			text-align center
			font-size 2rem
			font-weight 500

		.logo
			width 100%
			img
				width 100%
				height auto

		.close
			position absolute
			right -8px
			top -8px
			width 40px
			height 40px
			background dark_blue url(../assets/img/close.svg) center center no-repeat
			background-size 16px auto
			border-radius 50%
			border 4px solid yellow_light
			cursor pointer
			&:active
				transform translateY(1px)


</style>
