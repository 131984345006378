<template>
	<div v-if="slide" class="image-zoom"
		v-hammer:pan.vertical="(event)=> pan(event)"
		v-hammer:panend="(event)=> panend(event)"
		v-hammer:pinch="(event)=> pinch(event)"
		v-hammer:pinchend="(event)=> pinchend(event)"
	>
		<div class="bg" v-hammer:tap="()=> close()"></div>
		<div class="content" ref="content">
			
			<img :src="slide.url" :style="{ transform: 'scale(' + scale + ')' }">

			<div class="close" v-hammer:tap="()=> close()"></div>

		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'image-zoom',
	data() {
		return {
			loaded: false,
			scale: 1
		}
	},
	props: [ 'slide' ],
	computed: {
		...mapGetters([  ]),
		
	},
	methods: {
		pinch(e) {
			this.scale = e.scale
		},
		pinchend(e) {
			this.scale = 1
		},
		pan(e) {
			let target = this.$refs.content
			let y = e.deltaY
			if (y > 0) y = 0
			target.style.transform = 'translate(0, ' + y + 'px)'
		},
		panend(e) {
			let target = this.$refs.content
			let y = e.deltaY
			
			target.classList.add('panning')

			if (y < 0 && Math.abs(y) > target.clientHeight / 3) {
				target.style.transform = 'translate(0, -250%)'
				// target.style.opacity = 0
				this.close()
			} else  {
				// target.style.opacity = 1
				target.style.transform = 'translate(0, 0)'
			}

			setTimeout(() => {
				target.classList.remove('panning')
				target.style.transform = ''
			}, 250)
		},
		close() {
			this.$store.dispatch('edit_game_ui', { zoomed_slide: false })
		}
	}
}
</script>

<style lang="stylus" scoped>
@import '../../../assets/css/variables'


.image-zoom
	&.image-zoom-enter-active, &.image-zoom-leave-active
		transition 0.25s easeOutQuart
		// .content
		// 	transition 2.25s easeOutQuart
	&.image-zoom-enter, &.image-zoom-leave-to
		opacity 0
		// .content
		// 	transform translateY(-40px)


#app.desktop, #app.tablet
	.image-zoom
		position fixed
		.content
			width 60%
			box-shadow 0 0 8px 4px #000
			.close
				display block

.image-zoom
	position absolute
	left 0
	top 0
	right 0
	bottom 0
	width 100%
	height 100%
	display flex
	align-items center
	justify-content center
	flex-direction column
	.bg
		position absolute
		left 0
		top 0
		right 0
		bottom 0
		background-color alpha(#000, 75%)
	.content
		width 100%
		// height 100%
		&.panning
			transition transform 0.25s easeOutQuart
		img
			width 100%
			// &.transition
			// 	transition transform 0.15s easeOutQuart
		.close
			position absolute
			right -12px
			top -12px
			width 40px
			height 40px
			background darken(dark, 25%) url(../../../assets/img/close.svg) center center no-repeat
			background-size 16px auto
			border-radius 50%
			border 4px solid #fff
			cursor pointer
			box-shadow 0 0 8px 4px alpha(#000, 75%)
			display none
			&:active
				transform translateY(1px)
</style>
