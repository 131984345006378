<template>
	<div
		v-if="game"
		class="button"
		v-hammer:tap="launch_game"
		:inactive="!active"
	>
		<!-- <div class="main-img" v-html="buttonImg"></div> -->
		<div v-if="medalWon" class="fleur-de-lys"><img :src="medalWon" alt=""></div>
		<div v-if="!loading" class="text-on-button">{{ game.document.title }}</div>
		<div v-if="loading" class="loading"></div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import {getMedalWon} from "../../js/utils/achievements"

export default {
	name: 'game-button',
	data() {
		return {
			loading: false
		}
	},
	props: {
		city: { default: null },
		game: { default: null },
		color: { default: '' }
	},
	computed: {
		...mapGetters([ 'app', 'world', 'user' ]),
		buttonImg() {
			const tags = [ 'base', 'button', 'lys' ]
			return this.$medias.find({ tags, svg: true })
		},
		medalWon() {
			const { user, world: { current: { city: { slug } } } } = this
			const searchBy  = ['medal', slug]
			const tags = [ getMedalWon({ user, searchBy }) ]
			return this.$medias.find({ tags })
		},
		active() {
			if (!this.game.clientCriterias.length) return true
			let criteria = this.game.clientCriterias.find((c) => {
				return c.action == 'presence' && this.user.achievement.criteriasAchieved.find((achieved) => { return achieved.criteriaId == c.id })
			})
			if (criteria) return true
			return false
		}
	},
	methods: {
		launch_game() {
			if (this.active && !this.loading) {
				this.loading = true
				this.$router.push({ name: 'game', params: { city_slug: this.world.current.city.slug, game_id: this.game.id } })
			}
		}
	}
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'

.button
	cursor pointer
	position relative
	text-align center
	color #fff
	width calc(100% - 80px)
	height 56px
	flex center center
	background-color generic_blue
	border-radius 32px
	box-shadow 0 6px 6px alpha(#000, 30%)
	&:active
		box-shadow none
		transform translateY(6px)
	// .main-img
	// 	>>> svg
	// 		width calc(100% - 20px)
	// 		filter drop-shadow( 0 8px 10px rgba(0, 0, 0, .5))
	// 		&:active
	// 			transform translateY(1px)
	// 		&:hover
	// 			filter drop-shadow( 0 8px 10px rgba(0, 0, 0, .5)) brightness(90%)
	.fleur-de-lys
		position absolute
		bottom -16px
		right -8px
		width 48px
	
	.text-on-button
		text-transform uppercase
		font-size 2rem
	.loading
		absolute 0
		background url(../../assets/img/button-loader.svg) center center no-repeat
		background-size 40px
		cursor wait

</style>
